<template>
    <div>
        <div
            v-if="isLoaded"
            class="modal-dialog modal-dialog--access block block-mode-loading-refresh"
        >
            <div class="modal-content">
                <div class="modal-header modal-header_no-border">
                    <h3 class="block-title">
                        {{ title }}
                    </h3>

                    <button
                        class="btn-block-option close-modal"
                        type="button"
                        @click="$modal.hide('ModalMaterialDocuments')"
                    >
                        ×
                    </button>
                </div>
                <div class="block-content content modal-staff">
                    <div class="workers__desc">
                        {{ text }}
                    </div>

                    <div class="row">
                        <div class="col-lg-6 workers">
                            <div class="workers__col">
                                <div class="workers__body">
                                    <div class="tab-content">
                                        <div
                                            class="tab-pane fade active show"
                                            id="vue-access-department"
                                        >
                                            <div class="workers__search form-group">
                                                <svg
                                                    width="13"
                                                    height="13"
                                                    viewBox="0 0 13 13"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M2.16406 5.55739C2.16406 3.68324 3.68336 2.16394 5.55751 2.16394C7.43166 2.16394 8.95096 3.68324 8.95096 5.55739C8.95096 7.43154 7.43166 8.95084 5.55751 8.95084C3.68336 8.95084 2.16406 7.43154 2.16406 5.55739ZM5.55751 0.66394C2.85493 0.66394 0.664062 2.85481 0.664062 5.55739C0.664062 8.25997 2.85493 10.4508 5.55751 10.4508C6.63818 10.4508 7.63702 10.1005 8.44669 9.50729L11.5757 12.6364C11.8686 12.9293 12.3435 12.9293 12.6364 12.6364C12.9293 12.3435 12.9293 11.8686 12.6364 11.5757L9.50736 8.44664C10.1006 7.63696 10.451 6.63808 10.451 5.55739C10.451 2.85481 8.26009 0.66394 5.55751 0.66394Z"></path>
                                                </svg>

                                                <input
                                                    class="form-control round"
                                                    type="text"
                                                    placeholder="Поиск"
                                                    v-model="data.searchStructure"
                                                >
                                            </div>
                                            <div class="workers__tree">
                                                <ul class="workers__department department js-vue-department-tree">
                                                    <permission-tree-structure
                                                        @buildStructTree="clickStructure"
                                                        @buildPositionTree="clickPosition"
                                                        v-for="(item) in data.leftTree.structure"
                                                        :item="item"
                                                        :key="item.id"
                                                        :leftTree="true"
                                                    ></permission-tree-structure>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 workers workers--second">
                            <form class="workers__col">
                                <div class="workers__body">
                                    <div class="tab-content">
                                        <div class="tab-pane fade active show"
                                             id="vue-sel-access-department"
                                             role="tabpanel"
                                             aria-labelledby="material-tab"
                                        >
                                            <div class="workers__tree">
                                                <ul class="workers__department department">
                                                    <permission-tree-structure
                                                        v-for="(item) in data.rightTree.structure"
                                                        :key="item.id"
                                                        :item="item"
                                                        @buildStructTree="clickStructure"
                                                        @buildPositionTree="clickPosition"
                                                    ></permission-tree-structure>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="module-set__footer d-flex justify-content-center">
                        <button type="button" class="v-btn" @click="onSubmits">Сохранить</button>
                        <button class="v-btn v-btn--transparent" @click="$modal.hide('ModalMaterialDocuments')">Отменить</button>
                    </div>
                </div>
            </div>
        </div>
        <loading-spinner v-else/>
    </div>
</template>

<script>
    import session from '@/api/session';
    import PermissionTreeStructure from './PermissionTreeStructure';
    import { basicMethods } from './basic_methods';
    import LoadingSpinner from '@/components/LoadingSpinner';
    import { mapState } from 'vuex';

    export default {
        name: 'ModalMaterialDocuments',
        mixins: [
            basicMethods
        ],
        props: {
            showUsersTab: {
                required: false,
                default: true
            },
            afterUpdate: {
                type: Function,
                required: false
            },
            permissionSettings: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isLoaded: false,
                form: {
                    parentRight: null
                }
            };
        },
        components: {
            LoadingSpinner,
            PermissionTreeStructure
        },
        computed: {
            ...mapState('documents_data', ['documentItems'])
        },
        async created() {
            await this.loadGetDocument();
            await this.loadDefaultDocument();

            this.isLoaded = true;
        },
        methods: {
            onSubmits(e) {
                e.preventDefault();

                const data = this.dataPreparation();
                const structureList = data.structure;

                let structureIdList = [];

                Object.entries(structureList).forEach((element) => {
                    structureIdList = structureIdList.concat(element[1]);
                });

                session.post(`/api/v1/material/${this.permissionObjectID}/attach_documents/`, {
                    ids: structureIdList
                })
                    .then(() => {
                        this.$modal.hide('ModalMaterialDocuments', structureIdList);

                        if (this.afterUpdate) {
                            this.afterUpdate();
                        }
                    }).catch(() => {
                        this.$swal({
                            title: 'Ошибка!',
                            text: 'Во время сохранения произошла ошибка. Давайте попробуем ещё раз.',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1400
                        });
                    });
            }
        }
    };
</script>

<style lang="scss">
    .v--modal-overlay[data-modal="ModalMaterialDocuments"] {
        .modal-dialog--access .close-modal {
            top: 0;
            right: 0;
        }
        .modal-dialog--access.block {
            margin: 0;
        }
    }

    .modal {
        overflow-y: auto;
    }
    .show-permission {
        display: flex;
        background: rgba(0, 0, 0, 0.2);
    }

    .area_lock {
        opacity: 0.5;
        pointer-events: none;
    }

    .display_flex {
        display: flex !important;
    }

    .is_invalid {
        border-color: #ef5350;
    }

    .modal-dialog--access .workers__col{
        height: 100%;
    }

    .modal-dialog--access.block {
        margin: 0 auto;
    }

    @media (max-width: 660px) {.workers__body
        .workers--second {
            margin-top: 20px;
        }
    }

    .workers__tree {
        height: auto;
        min-height: 250px;
    }

    .workers__col {
        height: 100% !important;
    }

</style>
